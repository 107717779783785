/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $(document).on('click touch', function(e) {

          if($(e.target).parent().hasClass('menu-item-has-children')) {
            e.preventDefault();
            e.stopPropagation();

            var menuOpen = false;
            var parent = $(e.target).parent('.menu-item-has-children');
            var subMenu = $(e.target).siblings('.sub-menu');

            if(subMenu.hasClass('open') || parent.hasClass('open')) {
              menuOpen = true;
            }

            $('.menu-item-has-children').each(function() {
              if ($(this).hasClass('open')) {
                $(this).removeClass('open');
              }
            });

            $('.sub-menu').each(function() {
              if ($(this).hasClass('open')) {
                $(this).removeClass('open');
              }
            });

            if (menuOpen === false) {
              subMenu.addClass('open');
              parent.addClass('open');
            } else {
              subMenu.removeClass('open');
              parent.removeClass('open');
            }

          } else if(!$(e.target).hasClass('sub-menu') && $('.sub-menu').has($(e.target)).length === 0) {

            $('.menu-item-has-children').each(function() {
              if ($(this).hasClass('open')) {
                $(this).removeClass('open');
              }
            });

            $('.sub-menu').each(function() {
              if ($(this).hasClass('open')) {
                $(this).removeClass('open');
              }
            });

          }

        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        // toggles hamburger and nav open and closed states
        var removeClass = true;
        $(".hamburger").click(function () {
          $(".hamburger").toggleClass('is-active');
          $("#sideNav").toggleClass('sideNav-open');
          $(".sideNavBody").toggleClass('sideNavBody-push');
          removeClass = false;
        });

        $(".sideNav").click(function() {
          removeClass = false;
        });

        document.addEventListener('touchstart', function(e) {
          if (removeClass && !$(e.target).hasClass('sideNav') && $('.sideNav').has($(e.target)).length === 0) {
             $(".hamburger").removeClass('is-active');
             $("#sideNav").removeClass('sideNav-open');
             $(".sideNavBody").removeClass('sideNavBody-push');
          }
          removeClass = true;
        }, false);
        $(window).scroll(function () {
          if ($(this).scrollTop() > 100) {
            $('.scrollup').fadeIn();
          } else {
            $('.scrollup').fadeOut();
          }
        });

        $('.scrollup').click(function () {
          $("html, body").animate({
            scrollTop: 0
          }, 600);
          return false;
        });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
